import React, { useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { useI18next } from "gatsby-plugin-react-i18next";
import { getAlternateLang } from "../utils/alternate-lang";
import Brick from "../components/bricks/bricks";
import { Container } from "@mui/material";
import { isFullLength } from "../utils";
import { gtmPushUserInfo } from "../services/auth";

export const query = graphql`
  query CookiePolicy($language: String!) {
    cookiePolicyEn: strapiCookiePolicy(locale: { eq: "en" }) {
      pageContent
      pageContentConstrainedImages {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
      pageContentFullWidthImages {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
    cookiePolicyEs: strapiCookiePolicy(locale: { eq: "es-PA" }) {
      pageContent
      pageContentConstrainedImages {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
      pageContentFullWidthImages {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const CookiePolicy = ({ data }) => {
  const { t, language } = useI18next();
  const cookiePolicy =
    language === "en" ? data.cookiePolicyEn : data.cookiePolicyEs;
  const getImageContent = (i) => {
    return cookiePolicy.pageContentFullWidthImages[i]?.localFile !== null
      ? cookiePolicy.pageContentFullWidthImages[i]
      : cookiePolicy.pageContentConstrainedImages[i];
  };

  useEffect(() => {
    window.dataLayer?.push({
      event: "page_info",
      page_language: language,
      page_category: "Cookie_Policy",
      page_ta: "",
      page_type: "",
    });
    gtmPushUserInfo();
  }, [language]);

  return (
    <Layout>
      <SEO
        seo={{
          metaTitle: t("footer.cookiePolicy"),
          metaDescription: t("footer.cookiePolicy").toLocaleLowerCase(),
          linkAlternateHref:
            "/" + getAlternateLang(language) + "/cookie-policy",
          linkHrefLang: getAlternateLang(language),
          noindex: true,
        }}
      />
      {cookiePolicy.pageContent.map((content, i) => (
        <Container
          style={{
            paddingLeft: 0,
            paddingRight: 0,
          }}
          maxWidth={isFullLength(content) ? false : "lg"}
        >
          <Brick
            key={`content__${i}`}
            content={content}
            imageContent={getImageContent(i)}
          />
        </Container>
      ))}
    </Layout>
  );
};

export default CookiePolicy;
